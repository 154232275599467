
import { computed, defineComponent, inject, PropType, ref } from 'vue';
import { DingdingOutlined } from '@ant-design/icons-vue';

export interface Operator {
  name: string;
  desc?: string;
  dingTalkNum?: string;
  operateTime?: string;
}
export interface ProjectInfo {
  id: string | number;
  name: string;
  principal?: string; //负责人
  effectTime?: string; //生效时间
  current?: number; // 当前所属步骤
  operateList?: Operator[];
}

export default defineComponent({
  props: {
    projectInfo: {
      type: Object as PropType<ProjectInfo>,
      // 真实项目中，应该去掉该默认值
      default: () => {
        return {
          id: '12345',
          name: '项目名称',
          principal: 'sendya',
          effectTime: '2016-12-12 ~ 2017-12-12',
          current: 1,
          operateList: [
            {
              name: 'sendya',
              dingTalkNum: 'sendya',
              desc: '创建项目',
              operateTime: '2016-12-12 12:32',
            },
            {
              name: 'Amour1699',
              dingTalkNum: 'Amour1699',
              desc: '部门初审',
            },
            {
              name: 'zkwolf',
              dingTalkNum: 'zkwolf',
              desc: '财务复核',
            },
          ],
        };
      },
    },
  },
  setup() {
    const isMobile = inject('isMobile', ref(false));
    return {
      direction: computed(() => (isMobile.value ? 'vertical' : 'horizontal')),
    };
  },
  components: {
    DingdingOutlined,
  },
});
